<template>
  <div class="container">
    <div class="row">
      <div class="col-12" style="height: 34px">
        <service-navbar />
      </div>
      <div class="col-12">
        <router-view class="mt-3"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { default as ServiceNavbar } from "./Navbar";
export default {
  components: {
    ServiceNavbar,
  },
  name: "services",
};
</script>
