<template>
  <ul class="nav nav-tabs header-tabs">
    <li
      v-for="link in nav"
      :key="link.to"
      :class="link.dropdown ? `nav-item dropdown` : `nav-item`"
    >
      <!-- Dropdown -->
      <a
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-toggle="dropdown"
        v-if="link.dropdown"
      >
        <icon :type="link.icon" class="mr-1" />
        {{ link.label }}
      </a>
      <div class="dropdown-menu" v-if="link.dropdown">
        <router-link
          v-for="drop in link.dropdown"
          :key="drop.label"
          :to="getLink(drop.to)"
          class="dropdown-item"
          :class="name == drop.to ? `active` : ``"
        >
          <icon :type="drop.icon" class="mr-1" />
          {{ drop.label }}
        </router-link>
      </div>

      <!-- Single -->
      <router-link
        v-if="!link.dropdown"
        :to="getLink(link.to)"
        class="nav-link py-2"
        :class="name == link.to ? `active` : ``"
      >
        <icon :type="link.icon" class="mr-1" />
        {{ link.label }}
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: "ServiceNavbar",
  computed: {
    id: function () {
      return this.$route.params.id;
    },
    name: function () {
      return this.$route.name;
    },
  },
  data() {
    return {
      nav: [
        {
          label: "Service",
          to: "services.update",
          icon: "home",
        },
      ],
    };
  },
  methods: {
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.id,
        },
      };
    },
  },
};
</script>
